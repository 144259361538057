"use client"

import { useState } from "react"
import Link from "next/link"
import { GearIcon, GitHubLogoIcon, HomeIcon } from "@radix-ui/react-icons"

import { Button } from "@/components/ui/button"
import { SearchBar } from "@/components/app-search-bar"
import { SyncSettings } from "@/components/sync-settings"

export function AppHeader() {
  const [openSetting, setOpenSetting] = useState(false)

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-16 items-center">
        <img src="/logo.png" alt="Logo" className="h-12 w-auto" />
        <div className="flex flex-1 justify-between space-x-2 md:justify-end">
          <Button variant="ghost" size="icon" asChild>
            <Link href="/">
              <HomeIcon className="h-4 w-4" />
            </Link>
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setOpenSetting(true)}
          >
            <GearIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <SearchBar />
      <SyncSettings open={openSetting} setOpen={setOpenSetting} />
    </header>
  )
}