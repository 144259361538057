import "@/styles/globals.css"

import Head from "next/head"
import type { AppProps } from "next/app"

import { AppHeader } from "@/components/app-header"
import { Toaster } from "@/components/ui/toaster"
import { DatabaseProvider } from "@/rxdb/database-provider"

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Book Checklist</title>
        <meta name="description" content="For ecomic collector" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <DatabaseProvider>
        <AppHeader />
        <main>
          <div className="container my-4">
            <Component {...pageProps} />
          </div>
        </main>
        <Toaster />
      </DatabaseProvider>
    </>
  )
}