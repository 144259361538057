import { addRxPlugin, createRxDatabase } from "rxdb"
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie"
// import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode"
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder"

import { checklistSchema } from "./schema"

// addRxPlugin(RxDBDevModePlugin)
addRxPlugin(RxDBQueryBuilderPlugin)

export default async function initialize() {
  const db = await createRxDatabase({
    name: 'checklist-db',
    storage: getRxStorageDexie(),
  })

  const collection = await db.addCollections({
    checklist: {
      schema: checklistSchema,
    },
  })

  return db
}