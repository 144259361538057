import { RxQuery } from "rxdb"

export async function queryPatch<Doc>(
  query: RxQuery<Doc, any>,
  patch: Partial<Doc>
) {
  const docs = await query.exec()
  if (Array.isArray(docs)) {
    return Promise.all(docs.map(doc => doc.incrementalPatch(patch)))
  } else if (docs instanceof Map) {
    return Promise.all([...docs.values()].map(doc => doc.incrementalPatch(patch)))
  }
}