import { RxJsonSchema } from "rxdb"

export type ChecklistDoc = {
  id: string
  title: string
  authors: string[]
  publisher: string
  check?: boolean
  description?: string
}

export const checklistSchema: RxJsonSchema<ChecklistDoc> = {
  title: 'checklist',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100,
    },
    title: {
      type: 'string',
      maxLength: 255,
    },
    authors: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    publisher: {
      type: 'string',
    },
    check: {
      type: 'boolean',
    },
    description: {
      type: 'string',
    },
  },
  required: ['id', 'title', 'authors', 'publisher'],
}