"use client"

import { useRouter } from "next/navigation"
import { MagnifyingGlassIcon } from "@radix-ui/react-icons"

import { Input } from "@/components/ui/input"

export function SearchBar() {
  const router = useRouter()

  return (
    <div className="container flex items-center">
      <MagnifyingGlassIcon className="absolute w-6 h-6 text-gray-500 ml-2" />
      <Input
        id="search"
        placeholder="Search..."
        className="block h-12 w-full p-4 ps-10 text-md text-gray-900 rounded-none border-none shadow-none"
        onKeyDown={(e) => {
          const searchKey = e.currentTarget.value.trim()
          if (e.key === 'Enter') {
            router.push(`/search?q=${searchKey}`)
          }
        }}
      />
    </div>
  )
}