"use client"

import { useState } from "react"
import { useRxCollection } from "rxdb-hooks"
import { saveAs } from "file-saver"
import { ReloadIcon } from "@radix-ui/react-icons"

import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"

import useLocalStorage from "@/lib/useLocalStorage"
import type { ChecklistDoc } from "@/rxdb/schema"
import { queryPatch } from "@/rxdb/patch"

interface SyncSettingsProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export function SyncSettings({open, setOpen}: SyncSettingsProps) {
  const { toast } = useToast()
  const collection = useRxCollection<ChecklistDoc>('checklist')

  const [isBusy, setIsBusy] = useState(false)
  const [jsonLink, setJsonLink] = useState('db.json')

  const [lastSync, setLastSync] = useLocalStorage('lastSyncTimestamp', 0)

  const fetchJson = async (url: string) => {
    try {
      const res = await fetch(url)
      return await res.json()
    } catch (err) {
      console.error(err)
    }
  }

  const handleSync = async () => {
    setIsBusy(true)

    const content = await fetchJson(jsonLink)
    if (content && content.books) {
      const result = await collection?.find().where('check').eq(true).exec()
      const resultSet = new Set(result?.map(item => item.id))
      content.books.forEach((item: any) => {
        if (resultSet.has(item.id)) {
          item.check = true
        }
      })

      if (content.customTag) {
        await collection?.bulkUpsert(content.books)
        toast({
          title: 'Sync completed',
          description: `Custom db: ${content.customTag}`,
        })
      } else if (content.updatedAt) {
        if (content.updatedAt > lastSync) {
          await collection?.bulkUpsert(content.books)
          setLastSync(content.updatedAt)
          toast({
            title: 'Sync completed',
            description: `Current version: ${new Date(content.updatedAt).toLocaleString()}`,
          })
        } else {
          toast({
            title: 'Sync completed(No update)',
            description: `Current version: ${new Date(lastSync).toLocaleString()}`,
          })
        }
      }
    }

    setIsBusy(false)
  }

  const handleCleanCheck = async () => {
    setIsBusy(true)

    const query = collection?.find().where('check').eq(true)
    if (query) {
      const result = await queryPatch(query, {
        check: false
      })
      toast({
        title: 'Clean completed',
        description: `Clean count: ${result?.length}`,
      })
    }

    setIsBusy(false)
  }

  const selectJson = async () => {
    // @ts-ignore
    const picker = window.showOpenFilePicker
    if (picker) {
      try {
        const [fileHandle] = await picker()
        const file = await fileHandle.getFile()
        const content = await file.text()
        return JSON.parse(content)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleImportCheck = async () => {
    setIsBusy(true)

    const content = await selectJson()
    if (content && content.bookId) {
      const bookId: string[] = content.bookId

      const query = collection?.findByIds(bookId)
      if (query) {
        const result = await queryPatch(query, {
          check: true
        })
        toast({
          title: 'Import completed',
          description: `Import count: ${result?.length}`,
        })
      }
    }

    setIsBusy(false)
  }

  const handleExportCheck = async () => {
    setIsBusy(true)

    const result = await collection?.find().where('check').eq(true).exec()
    if (result) {
      const save = {
        bookId: result.map(item => item.id),
      }
      saveAs(new Blob([JSON.stringify(save)]), 'save.json')
      toast({
        title: 'Export completed',
        description: `Export count: ${result.length}`,
      })
    }

    setIsBusy(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>Settings</DialogTitle>
          <DialogDescription className="text-red-500">
            It may take long time...
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col space-y-6">
          <div className="flex items-center space-x-2">
            <span>Import</span>
            <Input
              id="link"
              value={jsonLink}
              onChange={(e) => setJsonLink(e.target.value)}
            />
            <Button size="sm" disabled={isBusy} onClick={() => handleSync()}>
              <ReloadIcon className="h-4 w-4"/>
            </Button>
          </div>
          <div className="flex justify-between space-x-2">
            <Button
              variant="destructive"
              size="sm"
              disabled
              onClick={() => handleCleanCheck()}
            >
              Clean all checks
            </Button>
            <Button
              variant="destructive"
              size="sm"
              disabled={isBusy}
              onClick={() => handleImportCheck()}
            >
              Import checks
            </Button>
            <Button
              variant="secondary"
              size="sm"
              disabled={isBusy}
              onClick={() => handleExportCheck()}
            >
              Export checks
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
