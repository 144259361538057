"use client"

import { useState } from "react"
import type { RxDatabase } from "rxdb"
import { Provider } from "rxdb-hooks"

import useEffectOnce from "@/lib/useEffectOnce"
import initialize from "./initialize"

export function DatabaseProvider({ children }: { children: React.ReactNode }) {
  const [db, setDb] = useState<RxDatabase>()

  useEffectOnce(() => {
    initialize().then(setDb)
  })

  return (
    <Provider db={db}>
      {children}
    </Provider>
  )
}